import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '~/routes/routes';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { observer } from 'mobx-react-lite';
export const UserTopMenu = observer((props) => {
    const [open, setOpen] = useState(false);
    const handleClickToggleMenu = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const navigate = useNavigate();
    const goToAccount = () => {
        handleClose();
        navigate(ROUTES.PERSONAL_INFO.path);
    };
    return (_jsxs("div", { className: "top-menu-wrap", children: [_jsx("div", { className: "username-wrap", children: "Alex" }), _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsxs("div", { className: "user-avatar-wrap", onClick: handleClickToggleMenu, children: [_jsx("figure", { children: _jsx("img", { src: "/assets/img/test-avatar.svg" }) }), open && (_jsxs("ul", { className: "dropdown-menu", children: [_jsx("li", { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/letter-w-user-menu.svg" }) }) }), _jsx("div", { className: "user-menu-text", children: "W Dollars $55" })] }) }), _jsx("li", { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/free-play-user-menu.svg" }) }) }), _jsx("div", { className: "user-menu-text", children: "Freepaly $55" })] }) }), _jsx("li", { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/unity-user-menu.svg" }) }) }), _jsx("div", { className: "user-menu-text", children: "Unity Points 550" })] }) }), _jsx("li", { children: _jsxs("div", { className: "user-menu-actions", children: [_jsx("button", { type: "button", className: "btn hr-blue-btn", onClick: goToAccount, children: "Account" }), _jsx("button", { type: "button", className: "btn hr-black-btn", onClick: handleClose, children: "Log out" })] }) })] }))] }) })] }));
});
